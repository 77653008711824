@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.mobile-login-container,
.mobile-login-header,
.mobile-login-content,
.mobile-login-button {
  display: none;
}
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

.login-title {
  font-weight: bold;
  margin: 0;
}

.login-subtitle {
  text-align: center;
}

.login-text {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
.login-link {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.login-button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  transition: transform 80ms ease-in;
}

.login-button:hover {
  cursor: pointer;
}

.login-button:active {
  transform: scale(0.95);
}

.login-button:focus {
  outline: none;
}

.login-button-ghost1 {
  background-color: transparent;
  border-color: #000;
  color: #000;
}

.login-button-ghost {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.login-form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow:
    0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  box-sizing: border-box;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.google-login-button {
  background-color: #4285f4;
  border: none;
  cursor: pointer;
  border-radius: 20px;

  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  padding: 18px 40px;
  transition: box-shadow 0.3s ease;
}

.google-login-button:hover {
  background-color: #3367d6 !important; /* important를 추가하여 인라인 스타일 오버라이드 */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

.google-login-button:hover i {
  background-color: #3367d6;
}

.google-login-button i {
  border-right: 2px solid white;
  margin-right: 10px;
  padding-right: 10px;
}

.service-info-container {
  padding: 20px;
  text-align: left;
}

.service-info-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.service-info-description {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #333;
}

.service-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 15px 0;
}

.service-link {
  color: #2196f3;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s;
}

.service-link:hover {
  color: #1976d2;
  text-decoration: underline;
}

.service-info-thanks {
  font-size: 0.9rem;
  color: #666;
  margin: 15px 0;
  line-height: 1.5;
}

.service-info-copyright {
  font-size: 0.8rem;
  color: #888;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .container,
  .form-container,
  .overlay-container {
    display: none;
  }

  .mobile-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: linear-gradient(45deg, #ff416c, #ff4b2b);
    padding: 100px 20px;
  }

  .mobile-login-header {
    display: block;
    margin-bottom: 40px;
  }
  .mobile-login-header h1 {
    text-align: center;
    font-size: 2.5rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
  }

  .mobile-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 360px;
    padding: 30px 20px;
    border-radius: 16px;
    background: linear-gradient(45deg, #ff416c, #ff4b2b);
    color: white;
    text-align: center;
    box-shadow: 0 4px 15px rgba(255, 65, 108, 0.2);
  }

  .mobile-login-content .login-subtitle {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .mobile-login-content .login-text {
    font-size: 0.95rem;
    opacity: 0.9;
    margin: 15px 0 25px;
    line-height: 1.5;
  }

  .mobile-login-button {
    background-color: #3367d6;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 14px 32px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .mobile-login-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }

  .mobile-login-button i {
    font-size: 1.2rem;
  }

  .service-info-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 12px;
    width: 100%;
    max-width: 360px;
  }

  .service-info-title {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 12px;
  }

  .service-info-description {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
  }
}
