.site-footer {
  width: 100%;
  margin-top: auto; /* 컨텐츠와 푸터 사이 공간 자동 조절 */
}

/* 전체 페이지 래퍼에 적용할 스타일 */
.site-footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
}
.site-footer-text {
  margin: 0;
  padding: 0 15px;
  border-right: 1px solid #ddd;
  line-height: 1;
}

.site-footer-text:last-child {
  border-right: none;
}

.site-footer-links {
  display: flex;
  gap: 12px;
  margin: 0;
}

@media (max-width: 768px) {
  .site-footer-info {
    flex-direction: column;
    text-align: center;
  }

  .site-footer-text {
    border-right: none;
    padding: 5px 0;
    font-size: 14px; /* 모바일에서 글자 크기 축소 */
  }

  .site-footer-links {
    font-size: 14px; /* 링크 텍스트도 함께 축소 */
  }
}

/* 더 작은 화면에서는 더 작게 조절 */
@media (max-width: 480px) {
  .site-footer-text {
    font-size: 12px;
  }

  .site-footer-links {
    font-size: 12px;
  }
}
